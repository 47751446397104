// Generated by Framer (552ec80)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {pka6KVk12: {hover: true}};

const serializationHash = "framer-IEcAW"

const variantClassNames = {pka6KVk12: "framer-v-1emaoj0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "pka6KVk12", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1emaoj0", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"pka6KVk12"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.8)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} variants={{"pka6KVk12-hover": {"--border-color": "rgb(255, 255, 255)"}}} {...addPropertyOverrides({"pka6KVk12-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SGVsdmV0aWNhIE5ldWU=", "--framer-font-family": "\"Helvetica Neue\", sans-serif", "--framer-line-height": "180%", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Navegar mais</motion.p></React.Fragment>} className={"framer-1bay8ww"} data-framer-name={"Our Services"} fonts={["Helvetica Neue"]} layoutDependency={layoutDependency} layoutId={"Ns9EE5bOR"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-IEcAW.framer-6uvpeo, .framer-IEcAW .framer-6uvpeo { display: block; }", ".framer-IEcAW.framer-1emaoj0 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; height: min-content; justify-content: space-between; overflow: visible; padding: 16px 32px 16px 32px; position: relative; width: 169px; }", ".framer-IEcAW .framer-1bay8ww { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-IEcAW[data-border=\"true\"]::after, .framer-IEcAW [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 61
 * @framerIntrinsicWidth 169
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"EKsj4h4nP":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerc_V9JYJ8m: React.ComponentType<Props> = withCSS(Component, css, "framer-IEcAW") as typeof Component;
export default Framerc_V9JYJ8m;

Framerc_V9JYJ8m.displayName = "button-navegar";

Framerc_V9JYJ8m.defaultProps = {height: 61, width: 169};

addFonts(Framerc_V9JYJ8m, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})